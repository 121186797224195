/* eslint-disable lines-between-class-members */
import BaseSearchFilter from './BaseSearchFilter'

export class IncomeExpenseSearchFilter extends BaseSearchFilter {
  /** @type {string} */
  #channel = ''
  #marketplace = ''
  #sourceType = ''
  #categoryIds = []
  #repeatingType = ''
  #categoryType = ''
  constructor() {
    super()
    this.#channel = this.channel
    this.#marketplace = this.marketplace
    this.#sourceType = this.sourceType
    this.#categoryIds = this.categoryIds
    this.#repeatingType = this.repeatingType
    this.#categoryType = this.categoryType
  }

  setDefaultFilters() {
    this.accountNo = ''
    this.companyId = 0
    this.storeId = 0
    this.warehouseId = 0
    this.beginDate = null
    this.endDate = null
    this.tenantType = 'SERVING'
    this.q = ''
    this.channel = 'ALL'
    this.marketplace = 'ALL'
    this.sourceType = ''
    this.categoryIds = []
    this.categoryType = 'Expense'
    this.repeatingType = ''
  }

  /** @returns {Object} filter object */
  getFilters() {
    const filter = {
      accountNo: this.accountNo,
      companyId: this.companyId,
      storeId: this.storeId,
      warehouseId: this.warehouseId,
      beginDate: this.beginDate,
      endDate: this.endDate,
      tenantType: this.tenantType,
      q: this.q,
      channel: this.channel,
      marketplace: this.marketplace,
      sourceType: this.sourceType,
      categoryIds: this.categoryIds,
      categoryType: this.categoryType,
      repeatingType: this.repeatingType,
    }
    return filter
  }

  /** @returns {string} */
  get marketplace() {
    return this.#marketplace
  }
  /** @param {string} value */
  set marketplace(value) {
    this.#marketplace = value
  }
  /** @returns {string} */
  get channel() {
    return this.#channel
  }
  /** @param {string} value */
  set channel(value) {
    this.#channel = value
  }
  /** @returns {string} */
  get sourceType() {
    return this.#sourceType
  }
  /** @param {string} value */
  set sourceType(value) {
    this.#sourceType = value
  }
  /** @returns {string} */
  get categoryIds() {
    return this.#categoryIds
  }
  /** @param {string} value */
  set categoryIds(value) {
    this.#categoryIds = value
  }
  /** @returns {string} */
  get repeatingType() {
    return this.#repeatingType
  }
  /** @param {string} value */
  set repeatingType(value) {
    this.#repeatingType = value
  }
  /** @returns {string} */
  get categoryType() {
    return this.#categoryType
  }
  /** @param {string} value */
  set categoryType(value) {
    this.#categoryType = value
  }
}
export default new IncomeExpenseSearchFilter()
